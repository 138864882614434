<template>
  <div class="label-printer flex flex-col gap-3">
    <div class="flex gap-3">
      <b-form-checkbox v-model="settings.resetText">
        Reset text on print
      </b-form-checkbox>
      <b-form-checkbox v-model="settings.resetDatetime">
        Reset date & time on print
      </b-form-checkbox>
      <div class="flex justify-end flex-1">
        <div class="flex gap-2">
          <b-button
            variant="white"
            size="sm"
            @click="duplicate"
          >
            Duplicate
          </b-button>

          <b-button
            variant="primary"
            size="sm"
            @click="edit"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="reset(true, true)"
          >
            Reset
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="fields.length > 0">
      <table class="table table-google">
        <thead>
          <tr>
            <th scope="col">Field</th>
            <th scope="col">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fields" :key="`field_${field.id}`" :ref="`field_${field.id}`">
            <td class="row-fit text-nowrap">{{ field.field_name }}</td>
            <td>
              <b-input-group
                size="sm"
                :prepend="field.text_prefix"
              >
                <b-form-input
                  v-if="field.text_type === 'text'"
                  v-model="field.text_content"
                  size="sm"
                  autocomplete="off"
                  @input="onInput"
                ></b-form-input>

                <b-form-input
                  v-if="field.text_type === 'number'"
                  v-model="field.text_content"
                  size="sm"
                  type="number"
                  @input="onInput"
                ></b-form-input>

                <v-select
                  v-if="field.text_type === 'dropdown'"
                  v-model="field.text_content"
                  :options="getFieldOptions(field)"
                  size="sm"
                  @input="onInput"
                  class="flex-1"
                  :clearable="false"
                />

                <b-form-input
                  v-if="field.text_type === 'date'"
                  v-model="field.date"
                  size="sm"
                  type="date"
                  @input="onInput"
                ></b-form-input>

                <b-form-input
                  v-if="field.text_type === 'time'"
                  v-model="field.time"
                  size="sm"
                  type="time"
                  @input="onInput"
                ></b-form-input>

                <b-input-group-append>
                  <b-input-group-text
                    v-if="field.text_suffix"
                  >
                    {{ field.text_suffix }}
                  </b-input-group-text>
                  <b-button
                    v-if="clearFields.includes(field.text_type)"
                    variant="light"
                    size="sm"
                    class="clear-button"
                    @click="onClear(field)"
                  >
                    &times;
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      No fillable fields available
    </div>

    <label-preview-floater
      :label="label"
      @print="reset(settings.resetText, settings.resetDatetime)"
    />

    <label-editor
      v-if="labelEditor.showModal"
      :data="labelEditor"
      :selectGroups="selectGroups"
      @deleted="onDeleted"
      @updated="onUpdated"
    />
  </div>
</template>

<script>
import { sortLabelFields } from '@/helpers';

const LabelPreviewFloater = () => import('@/components/labels/LabelPreviewFloater.vue');
const LabelEditor = () => import('@/components/labels/LabelEditor.vue');

export default {
  name: 'LabelPrinter',
  components: {
    LabelPreviewFloater,
    LabelEditor,
  },
  props: {
    id: String,
  },
  computed: {
    fields() {
      if (!this.label) return [];
      return sortLabelFields(this.label.fields).filter((field) => field.text_type !== 'static');
    },
  },
  data() {
    return {
      label: null,
      selectGroups: null,
      selectOptions: null,
      settings: {
        resetText: false,
        resetDatetime: true,
      },
      labelEditor: {
        showModal: false,
        labelId: null,
      },
      clearFields: ['date', 'time', 'dropdown'],
    };
  },
  methods: {
    initFocus() {
      const focusField = this.fields.find((field) => (field.text_type === 'text'));
      if (focusField) {
        const ref = this.$refs[`field_${focusField.id}`];
        if (!ref) return;
        ref[0].querySelector('input').focus();
      }
    },
    duplicate() {
      this.$emit('duplicate', this.label);
    },
    edit() {
      this.labelEditor.showModal = true;
      this.labelEditor.labelId = this.label.id;
    },
    reset(resetText, resetDatetime) {
      this.fields.forEach((field) => {
        if (resetText && field.text_type === 'text') {
          field.text_content = '';
        }
        if (resetText && field.text_type === 'dropdown') {
          field.text_content = '';
        }
        if (resetDatetime && field.text_type === 'date') {
          // eslint-disable-next-line prefer-destructuring
          field.date = new Date().toISOString().split('T')[0];
        }
        if (resetDatetime && field.text_type === 'time') {
          // eslint-disable-next-line prefer-destructuring
          field.time = new Date().toTimeString().slice(0, 5);
        }
      });
      this.initFocus();
    },
    fetchLabel() {
      this.label = null;
      this.$http
        .get(`/labels/${this.id}`)
        .then((res) => {
          res.body.fields.forEach((field) => {
            if (field.text_type === 'date') {
              // eslint-disable-next-line prefer-destructuring
              field.date = new Date().toISOString().split('T')[0];
            }
            if (field.text_type === 'time') {
              // eslint-disable-next-line prefer-destructuring
              field.time = new Date().toTimeString().slice(0, 5);
            }
          });
          this.label = res.body;
          if (this.label.label_name === '' && this.label.fields.length === 0) {
            this.edit();
          }
          this.onInput();
          this.$nextTick(() => {
            this.initFocus();
          });
        })
        .catch((err) => {
          alert(`Failed to fetch label: ${err.response.text}`);
        });
    },
    fetchSelectGroups() {
      this.$http
        .get('/select/groups')
        .then((res) => {
          this.selectGroups = res.body.groups.map((group) => ({
            text: group.group_name,
            value: group.group_key,
          }));
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch select groups: ${err.response.text}`);
        });
    },
    fetchSelectOptions() {
      this.$http
        .get('/select/download')
        .then((res) => {
          const selectOptions = {};
          Object.values(res.body.download).forEach((group) => {
            selectOptions[group.group_key] = group.options.map((option) => (
              `${group.option_prefix}${option.option_value}${group.option_suffix}`
            ));
          });
          this.selectOptions = selectOptions;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch select options: ${err.response.text}`);
        });
    },
    getFieldOptions(field) {
      if (field.text_type !== 'dropdown') {
        return [];
      }
      if (field.select_group_key === null) {
        return [];
      }
      if (this.selectOptions === null) {
        return [];
      }
      return this.selectOptions[field.select_group_key] ?? [];
    },
    onInput() {
      this.label.fields.forEach((field) => {
        if (field.text_type === 'date') {
          field.text_content = field.date.split('-').reverse().join('.');
        }
        if (field.text_type === 'time') {
          field.text_content = field.time;
        }
      });
    },
    onClear(field) {
      if (field.text_type === 'date') {
        field.date = '';
      }
      if (field.text_type === 'time') {
        field.time = '';
      }
      field.text_content = '';
      this.onInput();
    },
    onDeleted(labelId) {
      this.$emit('deleted', labelId);
    },
    onUpdated(labelId) {
      this.fetchLabel();
      this.$emit('updated', labelId);
    },
  },
  created() {
    this.fetchLabel();
    this.fetchSelectGroups();
    this.fetchSelectOptions();
  },
  watch: {
    id() {
      this.fetchLabel();
    },
  },
};
</script>

<style lang="scss" scoped>
.clear-button {
  border: 1px solid #dadce0;
  border-radius: 4px;
}
.label-printer {
  max-width: 650px;
}
</style>
